<template>
	<div class="container">
		<div class="ewm page-sub-box">
			<!-- 1차 -->
			<div class="flex flex-col gap-y-6 divide-y">
				<div>
					<div class="sub_title_txt my-4">
						<h2>설문상세</h2>
					</div>
					<table class="table_form line-bin">
						<tbody>
							<tr>
								<th scope="row">
									<label for="label5">
										<span>설문 제목</span>
									</label>
								</th>
								<td>
									{{ formData.surNm }}
								</td>
								<th scope="row">
									<label for="label5">
										<span>작성자</span>
									</label>
								</th>
								<td>{{ formData.regNm }} [{{ formData.regId }}]</td>
								<th scope="row">
									<label for="label5">
										<span>설문 기간</span>
									</label>
								</th>
								<td>{{ this.surFormatDt(formData.surStartDt) }} ~ {{ this.surFormatDt(formData.surEndDt) }}</td>
							</tr>
							<tr>
								<th scope="row">
									<label for="label5">
										<span>결과 공개 여부</span>
									</label>
								</th>
								<td>
									{{ formData.resultOpenFl }}
								</td>
								<th scope="row">
									<label for="label5">
										<span>설문 안내 문구</span>
									</label>
								</th>
								<td>
									{{ formData.sheetName }}
								</td>
							</tr>
						</tbody>

						<tfoot>
							<tr>
								<td>대상자 : {{ formData.targetPerson }}</td>
								<td>참여완료 : {{ formData.partiPerson }}</td>
								<td>미참여 : {{ formData.targetPerson - formData.partiPerson }}</td>
							</tr>
						</tfoot>
					</table>
				</div>

				<!-- 2차 -->
				<div class="flex gap-x-6">
					<div class="ml-2 w-1/2">
						<div class="sub_title_txt my-4">
							<h2>설문 항목</h2>
						</div>
						<div>
							<DxDataGrid
								class="grid-box"
								id="dataGrid1"
								ref="dataGrid1"
								:allow-column-reordering="true"
								:data-source="formData.surveyQuestions"
								key-expr="id"
								:show-column-headers="true"
								:show-borders="false"
								:column-auto-width="true"
								:show-row-lines="true"
								:show-column-lines="false"
								@row-click="onClickeduRow"
								height="100%"
								width="100%"
							>
								<DxFilterRow :visible="true" />
								<DxSelection mode="single" />
								<DxColumn caption="No" data-field="questionOrd" alignment="center" :allowFiltering="false" width="90" />
								<DxColumn caption="문항제목" data-field="questionNm" alignment="center" />
								<DxColumn caption="타입" data-field="questionTypeCd" alignment="center">
									<DxLookup :data-source="getSurTypeList" value-expr="id" display-expr="codeNm" />
								</DxColumn>
								<DxColumn
									caption="참여율"
									data-field="partiRate"
									alignment="center"
									:allowFiltering="false"
									:cell-template="cellTemplateByColumn"
								>
								</DxColumn>
							</DxDataGrid>
						</div>
					</div>

					<div class="mr-2 w-1/2">
						<div v-if="table.rowData.questionNm" class="sub_title_txt my-4">
							<h2>
								{{ `${table.rowData.questionOrd}. ${table.rowData.questionNm}` }}
								{{ table.rowData.importantAnswerFl == 'Y' ? '[필수]' : '' }}
							</h2>
						</div>

						<div v-if="selectedQuestionGroupCd === 1091">
							<div v-for="(item, index) in table.depsList" :key="index">
								<div class="flex justify-around gap-x-6 p-6 bg-gray-100">
									<div>대상자 : {{ table.rowData.targetPerson }}</div>
									<div v-if="formData.resultOpenFl === '비공개'">참여자 : {{ table.rowData.partiPerson }}</div>
									<div v-else>
										<a @click="onPartiPersonClick(table.rowData.id)" class="cursor-pointer"
											>참여자 : {{ table.rowData.partiPerson }}</a
										>
									</div>
									<div>참여율 : {{ table.rowData.partiRate }}</div>
									<div>평균점수 : {{ item.surveyAnswer.avgScore }}</div>
								</div>
							</div>
						</div>
						<div v-if="selectedQuestionGroupCd === 1090">
							<div v-for="(item, index) in table.depsList" :key="index">
								<DxDataGrid
									class="grid-box"
									:allow-column-reordering="true"
									:data-source="item.surveyAnswer"
									:no-data-text="getNoDataText()"
									:show-column-headers="true"
									:show-borders="false"
									:column-auto-width="true"
									:show-row-lines="true"
									:show-column-lines="false"
									height="100%"
									width="100%"
								>
									<DxFilterRow :visible="true" />
									<DxSelection mode="single" />
									<DxColumn
										caption="부서정보"
										:data-field="formData.resultOpenFl === '비공개' ? '-' : 'deptNmPath'"
										alignment="center"
										:calculate-cell-value="rowData => (formData.resultOpenFl === '비공개' ? '-' : rowData.deptNmPath)"
									/>
									<DxColumn
										caption="상담사 [ID]"
										data-field="agtNm"
										alignment="center"
										:calculate-cell-value="
											rowData => (formData.resultOpenFl === '비공개' ? '-' : `${rowData.agtNm}[${rowData.agtId}]`)
										"
									/>
									<DxColumn
										caption="입력 값"
										data-field="answer"
										alignment="center"
										:calculate-cell-value="rowData => (rowData.answer == 'undefined' ? '-' : rowData.answer)"
									/>
									<DxColumn
										caption="입력일시"
										data-field="regDt"
										data-type="date"
										alignment="center"
										format="yyyy-MM-dd HH:mm"
									>
									</DxColumn>
								</DxDataGrid>
							</div>
						</div>
						<div v-if="selectedQuestionGroupCd === 1089">
							<div v-for="(item, index) in table.depsList" :key="index">
								<div class="pies-container">
									<DxPieChart
										:data-source="item.surveyAnswer"
										palette="Soft Pastel"
										class="pie"
										@point-click="onPointClick"
									>
										<DxSeries argument-field="questionView" value-field="choiceCount">
											<DxLabel :visible="true" :customize-text="formatLabel" background-color="none">
												<DxConnector :visible="true" :width="1" />
												<DxFont :size="20" />
											</DxLabel>
										</DxSeries>
										<DxSize :height="300"/>
										<DxLegend item-text-position="right" horizontal-alignment="right" vertical-alignment="top" />
									</DxPieChart>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<section class="terms bottom-btn-box">
			<div class="page-sub-box">
				<h2 class="hidden">일반 버튼</h2>
				<div class="bottom-btn-wrap">
					<DxButton text="취 소" :width="120" :height="40" class="white filled txt_S medium" @click="onCancelFormData" />
				</div>
			</div>
		</section>

		<!-- Modal Layer -->
		<DxPopup
			:show-title="true"
			:title="modal.initData ? modal.initData.title : null"
			:min-width="modal.initData ? modal.initData.width : null"
			:width="modal.initData ? modal.initData.width : null"
			:min-height="modal.initData ? modal.initData.height : null"
			:height="modal.initData ? modal.initData.height : null"
			:drag-enabled="true"
			:resize-enabled="true"
			:show-close-button="true"
			:close-on-outside-click="false"
			v-model="modal.isOpened"
			:visible="modal.isOpened"
			@hiding="isOpenModal(false)"
		>
			<template #content>
				<div>
					<component :is="modal.currentComponent" :contentData="modal.contentData" v-model="modal.contentData"></component>
				</div>
			</template>
			<DxToolbarItem
				widget="dxButton"
				toolbar="bottom"
				location="center"
				:visible="
					modal.initData.hasOwnProperty('buttons')
						? modal.initData.buttons.hasOwnProperty('cancel')
							? modal.initData.buttons.hasOwnProperty('cancel')
							: !modal.initData.buttons.hasOwnProperty('cancel')
						: false
				"
				:options="{
					elementAttr: {
						class: 'white filled txt_S medium',
					},
					text: modal.initData.hasOwnProperty('buttons')
						? modal.initData.buttons.hasOwnProperty('cancel')
							? modal.initData.buttons.cancel.text
							: ''
						: '',
					width: '120',
					height: '40',
					onClick: () => {
						isOpenModal(false);
					},
				}"
			/>
		</DxPopup>
		<!-- /Modal Layer -->
	</div>
</template>

<script>
import { DxButton } from 'devextreme-vue/button';
import { isSuccess } from '@/plugins/common-lib';
import { DxPopup, DxToolbarItem } from 'devextreme-vue/popup';
import ModalPartiList from '@/pages/ewm/surveys/implementation/modal-partiList.vue';
import { DxDataGrid, DxColumn, DxSelection, DxFilterRow, DxLookup } from 'devextreme-vue/data-grid';
import DxPieChart, { DxSize, DxSeries, DxLabel, DxLegend, DxConnector, DxFont } from 'devextreme-vue/pie-chart';

export default {
	components: {
		DxButton,
		DxDataGrid,
		DxColumn,
		DxSelection,
		DxFilterRow,
		DxLookup,

		DxPieChart,
		DxSize,
		DxSeries,
		DxLabel,
		DxLegend,
		DxConnector,
		DxFont,

		DxPopup,
		DxToolbarItem,
		ModalPartiList,
	},
	props: {},
	watch: {},
	data() {
		return {
			selectedQuestionGroupCd: null,
			formData: {},
			table: {
				rowData: [],
				depsList: [],
			},
			modal: {
				isOpened: false,
				currentComponent: null,
				initData: {},
				contentData: null,
			},
		};
	},
	computed: {
		/** @description : 설문 문항 타입 코드 테이블에서 가져오기 */
		getSurTypeList() {
			return this.$_getCode('ewm_sur_type');
		},
	},
	methods: {
		getNoDataText() {
			return this.$_msgContents('CMN_NO_DATA');
		},
		//pieChart click event
		onPointClick({ target }) {
			target.select();
			// console.log(e.target);
		},
		//pieChart click event
		formatLabel({ valueText, percentText }) {
			return `${valueText} (${percentText})`;
		},
		/** @description : dataGrid row click event 메서드 */
		async onClickeduRow(e) {
			console.log('e.data', e.data);
			const params = { questionId: e.data.id };
			const payload = {
				actionname: 'EWM_SURVEY_SCHEDULE_RESULT_ANSWER',
				data: params,
				loading: false,
				useErrorPopup: true,
			};
			const res = await this.CALL_EWM_API(payload);
			if (isSuccess(res)) {
				this.selectedQuestionGroupCd = e.data.questionTypeCd;
				this.table.rowData = e.data;

				this.table.depsList = this.$_commonlib.cloneObj(res.data.data);

				if (e.data.questionTypeCd === 1091) {
					// 점수형
					//this.table.deps1List = this.$_commonlib.cloneObj(res.data.data);
				} else if (e.data.questionTypeCd === 1090) {
					//서술형
					//this.table.deps2List = this.$_commonlib.cloneObj(res.data.data);
				} else if (e.data.questionTypeCd === 1089) {
					//객관형
					//this.table.deps3List = this.$_commonlib.cloneObj(res.data.data);
					this.table.depsList.forEach(d1 => {
						d1.surveyAnswer.forEach(d2 => {
							d2.questionView = `${d2.questionView} (${d2.choiceCount}명)`;
						});
					});
				}
			}
		},
		/** @description : 결과 공개 시 보여줄 모달창 */
		async onPartiPersonClick(id) {
			const params = { questionId: id };
			const payload = {
				actionname: 'EWM_SURVEY_SCHEDULE_RESULT_ANSWER_DETL_LIST',
				data: params,
				loading: false,
				useErrorPopup: true,
			};
			const res = await this.CALL_EWM_API(payload);
			if (isSuccess(res)) {
				this.onOpenModal(
					'ModalPartiList',
					{
						title: '참여자 리스트',
						buttons: {
							cancel: { text: '닫기' },
						},
						width: 600,
						height: 600,
					},
					this.$_commonlib.cloneObj(res.data.data[0]),
				);
			}
		},
		onOpenModal(componentNm, componentInitData, data) {
			this.modal.currentComponent = componentNm;
			this.modal.initData = componentInitData;
			this.modal.contentData = data;
			this.isOpenModal(true);
		},
		/** @description: 팝업 오픈 체크 메서드 */
		isOpenModal(data) {
			this.modal.isOpened = data;
			if (!data) {
				this.modal.currentComponent = null;
				this.modal.initData = {};
			}
		},
		/** @description : 설문 기간 날짜 형식 변환 메서드 */
		surFormatDt(date) {
			return this.$_commonlib.formatDate(date, 'YYYYMMDD', 'YYYY.MM.DD');
		},
		/** @description : 참여율, 참여인원 형식 변환 메서드 */
		cellTemplateByColumn: async (container, option) => {
			let tag = document.createElement('div');
			let value = `${option.data.partiPerson}/${option.data.targetPerson} ( ${option.data.partiRate}% )`;
			tag.innerHTML = value;
			container.append(tag);
		},
		/** @description: 취소 버튼 클릭 이벤트 */
		onCancelFormData() {
			if (this.reqParams.route) {
				this.$router.push({ path: '/ewm/personal/survey' });
			} else {
				this.$router.push({ path: '/ewm/surveys/implementation/list' });
			}
		},
		/** @description: 데이터 조회 메서드 */
		async selectFormData() {
			const params = { scheId: this.formData.id };

			const payload = {
				actionname: 'EWM_SURVEY_SCHEDULE_RESULT',
				data: params,
				loading: false,
				useErrorPopup: true,
			};

			const res = await this.CALL_EWM_API(payload);

			if (res.status === 200) {
				if (res.data.header.resCode === 'success') {
					let data = res.data.data[0];
					data.surveyQuestions.forEach((reg, index) => {
						reg.questionOrd = index + 1;
					});
					data.resultOpenFl = data.resultOpenFl === 'Y' ? '공개' : '비공개';
					this.formData = data;
				}
			}
		},
		/** @description : 라이프사이클 created시 호출되는 메서드 */
		createdData() {
			this.reqParams = this.$store.getters.getDetailParams;
			if (!this.reqParams) {
				this.$_goPrePage();
				return;
			}
			this.formData.id = this.reqParams.id; //id
			this.selectFormData();
		},
		/** @description: 라이프사이클 mounted시 호출되는 메서드 */
		mountedData() {},
	},
	created() {
		this.createdData();
	},
	mounted() {},
};
</script>

<style scoped>
.ui-glid-box {
	padding: 20px;
}

.table_form td > div {
	display: inline-block;
	vertical-align: middle;
}

.table_form td .empty-box {
	width: 10px;
}

.tableRound {
	border: 2px solid #2f539b;
	border-radius: 20px;
	padding: 20px;
}
</style>
